import http from '@/http.js'

const state = () => ({
  ufs: ['AC','AL','AP','AM','BA','CE','DF','ES','GO','MA','MT','MS','MG','PA','PB','PR','PE','PI','RJ','RN','RS','RO','RR','SC','SP','SE','TO'],
  municipios: []
})

// mutations
const mutations = {
  DefinirMunicipios (state, municipios) {
    state.municipios = municipios
  },  
}

// actions
const actions = {
  async CarregarMunicipios({ commit }) {
    try {
      var response = await http.get(`/api/municipios`)
      commit('DefinirMunicipios', response.data.data)
    } catch(e) {
      console.error(e)
      commit('DefinirMunicipios', [])
    }
  }
}

// getters
const getters = {
  ufs: state => state.ufs,
  municipiosUF: (state) => (uf) => {
    return state.municipios.filter(municipio => municipio.uf.sigla === uf)
  },
  municipios: state => state.municipios
}

export default {
  state,
  getters,
  actions,
  mutations
}