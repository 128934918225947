import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import store from '@/store'

const ConfirmandoEmail = () => import('@/views/usuario/ConfirmandoEmail.vue')
const ConsultarNSU = () => import('@/views/distribuicaoDFe/ConsultarSefaz.vue')
const Dashboard = () => import('@/views/dashboard/Dashboard.vue')
const Entrega = () => import('@/views/pedido/Entrega.vue')
const Entregas = () => import('@/views/pedido/Entregas.vue')
const EsqueceuASenha = () => import('@/views/usuario/EsqueceuASenha.vue')
const EventosDFe = () => import('@/views/vendas/EventosDFe.vue')
const FormasPagamento = () => import('@/views/formasPagamento/FormasPagamento.vue')
const Informacoes = () => import('@/views/informacoes/Informacoes.vue')
const Login = () => import('@/views/usuario/Login.vue')
const NSUs = () => import('@/views/distribuicaoDFe/NSUs.vue')
const PageNotFound = () => import('@/views/PageNotFound.vue')
const PagamentosGerencianetEnviar = () => import('@/views/pagamentos/gerencianet/Pagamentos.vue')
const Pagamentos = () => import('@/views/pagamentos/Pagamentos.vue')
const Pedido = () => import('@/views/pedido/Pedido.vue')
const Pedidos = () => import('@/views/pedidos/Pedidos.vue')
const Pessoas = () => import('@/views/pessoas/Pessoas.vue')
const Pessoa = () => import('@/views/pessoa/Pessoa.vue')
const PosicaoPagamento = () => import('@/views/pagamentos/posicao/Posicao.vue')
const Produtos = () => import('@/views/produtos/Produtos.vue')
const RedefinirSenha = () => import('@/views/usuario/RedefinirSenha.vue')
const RelatoriosVendas = () => import('@/views/relatorios/Vendas.vue')
const ResumoNFes = () => import('@/views/distribuicaoDFe/ResumoNFes.vue')
const Venda = () => import('@/views/venda/Venda.vue')
const Vendas = () => import('@/views/vendas/Vendas.vue')
const FichaPessoa = () => import('@/views/pessoa/ficha/Ficha.vue')

const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/distribuicaoDFe/nsus', name: 'NSUs', component: NSUs },
  { path: '/distribuicaoDFe/consultar', name: 'Consultar', component: ConsultarNSU },
  { path: '/distribuicaoDFe/resumoNFes', name: 'ResumoNFes', component: ResumoNFes },      
  { path: '/formasPagamento', name: 'FormasPagamento', component: FormasPagamento },  
  { path: '/informacoes', name: 'Informacoes', component: Informacoes },
  { path: '/pagamentos', name: 'Pagamentos', component: Pagamentos },
  { path: '/pagamentos/gerencianet/enviar', name: 'PagamentosPendentesEnvio', component: PagamentosGerencianetEnviar },
  { path: '/pagamentos/posicao', name: 'PosicaoPagamento', component: PosicaoPagamento },
  { path: '/pedido/:id', name: 'Pedido', component: Pedido },
  { path: '/pedido/entrega', name: 'AdicionarEntrega', component: Entrega }, 
  { path: '/pedidos/entregas', name: 'Entregas', component: Entregas },
  { path: '/pedidos', name: 'Pedidos', component: Pedidos },  
  { path: '/pessoas', name: 'Pessoas', component: Pessoas },
  { path: '/pessoa', name: 'NovaPessoa', component: Pessoa },
  { path: '/pessoa/:id', name: 'Pessoa', component: Pessoa },
  { path: '/pessoa/:id/ficha', name: 'FichaPessoa', component: FichaPessoa },  
  { path: '/produtos', name: 'Produtos', component: Produtos },
  { path: '/relatorios/vendas', name: 'RelatoriosVendas', component: RelatoriosVendas },  
  { path: '/vendas', name: 'Vendas', component: Vendas },  
  { path: '/vendas/dfe/eventos', name: 'DFeEventos', component: EventosDFe },
  { path: '/venda/:id', name: 'Venda', component: Venda },
  { path: '/:catchAll(.*)*', name: "PageNotFound", component: PageNotFound, }
]

const router = createRouter({
  history: createWebHistory('/'),
  routes
})

router.addRoute({ path: '/login', name: 'Login', component: Login, meta: { publico: true } })
router.addRoute({ path: '/usuario/esqueceuASenha', name: 'EsqueceuASenha', component: EsqueceuASenha, meta: { publico: true }})
router.addRoute({ path: '/usuario/redefinirSenha', name: 'RedefinirSenha', component: RedefinirSenha, meta: { publico: true }})
router.addRoute({ path: '/usuario/confirmandoEmail', name: 'ConfirmandoEmail', component: ConfirmandoEmail, meta: { publico: true }})

document.title = 'AlgoPlus++'

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.publico) {
    next()
  } else if (!store.getters.authenticated) {
    store.commit('ExibirHeader', false)
    next('/login')
  } else {
    next()
  }
})

export default router
