import moment from 'moment-timezone'

/**
 * Format value to currency
 * @param value {decimal}
 * @return {string}
 */
export function FormatCurrency (value) {
  if (!value) { value = 0 }
  return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
}

/**
 * Format value to Date BR
 * @param value {date}
 * @return {string}
 */
export function FormatDate (date) {
  if (date) {
    return moment(date).format('DD/MM/YY')
  }
}

/**
 * Format value to DateTime BR
 * @param value {date}
 * @return {string}
 */
export function FormatDateTime (date) {
  try 
  {
    return moment(date).utcOffset(0, false).format('DD/MM/YY HH:mm:ss')
  } 
  catch(e)
  {
    return ''
  } 
}

export function FormatDateTimeWithTz(date, tz) {
  try 
  {
    return moment(date).utcOffset(tz, false).format('DD/MM/YY HH:mm:ss')
  } 
  catch(e)
  {
    return ''
  } 
}

/**
 * Format value to Percent
 * @param value {decimal}
 * @return {string}
 */
export function FormatPercent (value, precision) {
  return FormatQtq(value, precision)
}

export function FormatQtq (value, precision) {
  if (!value) { value = 0 }
  if (!precision) { precision = 2 }
  return value.toFixed(precision)
}

const milissegundos_por_dia = 1000 * 60 * 60 * 24;
const start_day_week = 0
const end_day_week = 6

export function InicioDaSemana(dataBase) {
  return CalcularData(dataBase, start_day_week)
}

export function FinalDaSemana(dataBase) {
  return CalcularData(dataBase, end_day_week)
}

function CalcularData(database, dayweek) {
  if (!database) { database = new Date() }
  var dif_milisegundos = (dayweek - database.getDay()) * milissegundos_por_dia
  var data_calculada = new Date(database.getTime() + dif_milisegundos);  
  return data_calculada
}

export function FormatarCpfCnpj(valor) {
  try {
    if(valor.length == 11) {
      return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
    } else if (valor.length == 14) {
      return valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
    } else {
      return valor
    }
  } catch (error) {
    return valor
  }
}

export function FormatarVctoToString (date) {
  if (date) {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear()

    return `${day}/${month}/${year}`
  }
}