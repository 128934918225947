import http from '@/http.js'
import store from '@/store'
import router from '@/router/index.js'
export default class InfoServico {
  ObterEmpresa () {
    return new Promise((resolve, reject) => {
      http.get(`/api/info/empresa`)
      .then((response) => { 
        store.commit('DefinirEmpresa', response.data.data)
        resolve(response.data.data)
      })
      .catch((error) =>  reject(error))
    })
  }
  ObterSistema () {
    return new Promise((resolve, reject) => {
      http.get(`/api/info/sistema`)
      .then((response) => { 
        store.commit('DefinirSistema', response.data.data)
        resolve(response.data.data)
      })
      .catch((error) =>  reject(error))
    })
  }
  ObterInfoCertificado() {    
    return new Promise((resolve, reject) => {
      http.get(`/api/info/certificado`)
      .then((response) => { 
        resolve(response.data.data)
      })
      .catch((error) =>  reject(error))
    })    
  }
  ObterInfoAvancado() {    
    return new Promise((resolve, reject) => {
      http.get(`/api/info/sistema/config/avancado`)
      .then((response) => { 
        resolve(response.data.data)
      })
      .catch((error) =>  reject(error))
    })    
  }  
  Online () {
    return new Promise((resolve, reject) => {
      http.head(`/api/info/online`)
      .then(() => { 
        resolve()
      })
      .catch((error) => {
        if (error.response.status === 401) {
          router.push('/login')
        }
        reject(error)
      })
    })       
  }
  ObterMenu() {    
    return new Promise((resolve, reject) => {
      http.get(`/api/info/menu`)
      .then((response) => { 
        resolve(response.data.data)
      })
      .catch((error) =>  reject(error))
    })    
  }  
}