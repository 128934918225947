<template>
  <div></div>
</template>

<script setup>
import { onMounted } from "vue";

onMounted(() => {
  console.log('=========== ALGOPLUS HOME ===========')
  console.log('==== http://algoplus.com.br')
  console.log('==== CARLOS ALEXANDRE LARANJEIRAS')
  console.log('==== Whats: (21) 997706037')
  console.log('=====================================')  
})
</script>
