<template>
  <footer class="p-3 fixed-bottom mb-3" v-if='!online' style="z-index: 1000;">
    <div class="alert alert-danger" role="alert">
      <i class="bi bi-wifi-off"></i>
      SEM CONEXÃO COM O SERVIDOR
    </div>
  </footer>
  <footer class="py-1 fixed-bottom bg-orange">
    <div class="container text-center">
      <div class="row">
        <div class="col">
          <small>Copyright &copy; AlgoPlus</small>
        </div>
        <div class="col d-none d-lg-block" v-if='state.empresa'>
          <small>
            {{ labelEmpresa }}
          </small>
        </div>
        <div class="col d-none d-lg-block" v-if='state.sistema'>
          <small>{{ labelAmbiente }}</small>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { computed, onMounted, reactive } from "vue"  
import { useStore } from 'vuex'  
import InfoServico from "@/servicos/InfoServico";
import { FormatarCpfCnpj } from '@/tools/Format.js'

const store = useStore()

const state = reactive({
  empresa: null,
  sistema: null
})

const online = computed(() => {
  return store.getters.online
})

onMounted(async () => {
  let servico = new InfoServico()
  state.empresa = await servico.ObterEmpresa()
  state.sistema = await servico.ObterSistema() 
})

const labelEmpresa = computed(() => {
  try {
    let nome = state.empresa.nomeFantasia ? state.empresa.nomeFantasia : state.empresa.razaoSocial
    let cnpj = FormatarCpfCnpj(state.empresa.cpfCnpj)
    try {
      nome = nome.substring(0, 20)
    } catch (error) {
      console.error(error)
    }
    return `${nome.trim()} | ${cnpj}`    
  } catch (error) {
    console.warn(error)
    return 'SEM INFORMAÇÃO'
  }
})

const labelAmbiente = computed(() => {
  try {
    let ambiente = state.sistema.xAmbiente
    return `Ambiente: ${ambiente}`    
  } catch (error) {
    console.warn(error)
    return 'SEM AMBIENTE'
  }
})

</script>

<style scoped>
.bg-orange {
  background-color: #ff8817;
  color: #0e1315;
  font-family: Poppins, sans-serif;
  font-size: 1rem;
  font-weight: normal;  
}
</style>