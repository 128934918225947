<template>
  <nav class="navbar navbar-expand-lg navbar-dark top-bar">
    <div class="container-fluid" v-if="state.menu">
      <button
        class="navbar-toggler"
        id="btn-toggle"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#headernavbar"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <a class="navbar-brand hand" @click="moveTo('/')" v-if="state.informacoes">
        {{ state.informacoes.titulo }}
      </a>
      <div class="collapse navbar-collapse" id="headernavbar">
        <ul class="navbar-nav d-flex my-2 justify-content-center my-md-0 text-small">
          <li class="nav-item" v-if="state.menu.cadastros.pessoas">
            <a class="nav-link hand" aria-current="page" @click="moveTo('/pessoas')">
              Clientes/Pessoas
            </a>
          </li>
          <li class="nav-item dropdown" v-if="state.menu.estoque.root">
            <a
              class="nav-link dropdown-toggle"
              href="javascript:void(0)"
              role="button"
              data-bs-toggle="dropdown"
            >
              Estoque
            </a>
            <ul class="dropdown-menu">
              <li>
                <a
                  class="dropdown-item"
                  href="javascript:void(0)"
                  @click="moveTo('/produtos')"
                >
                  Tabela de Produtos
                </a>
              </li>
            </ul>            
          </li>          
          <li class="nav-item dropdown" v-if="state.menu.pedidos">
            <a
              class="nav-link dropdown-toggle"
              href="javascript:void(0)"
              role="button"
              data-bs-toggle="dropdown"
            >
              Pedidos
            </a>
            <ul class="dropdown-menu">
              <li>
                <a
                  class="dropdown-item"
                  href="javascript:void(0)"
                  @click="moveTo('/pedidos')"
                >
                  Pedidos
                </a>
              </li>
              <li>
                <hr class="dropdown-divider" />
              </li>
              <li>
                <a
                  class="dropdown-item"
                  href="javascript:void(0)"
                  @click="moveTo('/pedido/entrega')"
                >
                  Adicionar Entrega
                </a>
              </li>              
              <li>
                <a
                  class="dropdown-item"
                  href="javascript:void(0)"
                  @click="moveTo('/pedidos/entregas')"
                >
                  Controle de Entregas
                </a>
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown" v-if="state.menu.vendas">
            <a
              class="nav-link dropdown-toggle"
              href="javascript:void(0)"
              role="button"
              data-bs-toggle="dropdown"
            >
              Vendas/Fiscal
            </a>
            <ul class="dropdown-menu">
              <li v-if="state.menu.vendas.listarVendas">
                <a class="dropdown-item" href="javascript:void(0)" @click="moveTo('/vendas')">Listar NFe(s)</a>
              </li>
              <li><hr class="dropdown-divider"  v-if="state.menu.vendas.listarVendas"/></li>
              <li v-if="state.menu.vendas.monitorEventosFiscais">
                <a
                  class="dropdown-item"
                  href="javascript:void(0)"
                  @click="moveTo('/vendas/dfe/eventos')"
                >
                  Monitor de Eventos Fiscais
                </a>
              </li>
              <li><hr class="dropdown-divider" v-if="state.menu.vendas.monitorEventosFiscais"/></li>
              <li v-if="state.menu.vendas.relatorios">
                <a
                  class="dropdown-item"
                  href="javascript:void(0)"
                  @click="moveTo('/relatorios/vendas')"
                >
                  Relatórios de Vendas
                </a>
              </li>              
            </ul>
          </li>
          <li class="nav-item dropdown" v-if="state.menu.pagamentos">
            <a
              class="nav-link dropdown-toggle"
              href="javascript:void(0)"
              role="button"
              data-bs-toggle="dropdown"
            >
              Pagamentos
            </a>
            <ul class="dropdown-menu">
              <li>
                <a
                  class="dropdown-item"
                  href="javascript:void(0)"
                  @click="moveTo('/pagamentos')"
                  >Abertos</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item"
                  href="javascript:void(0)"
                  @click="moveTo('/pagamentos/posicao')"
                  >Posição Retroativa</a
                >
              </li>              
              <li><hr class="dropdown-divider" /></li>
              <li>
                <a
                  class="dropdown-item"
                  href="javascript:void(0)"
                  @click="moveTo('/formasPagamento')"
                  >Formas Pagamento</a
                >
              </li>
              <li><hr class="dropdown-divider" /></li>
              <li>
                <a
                  class="dropdown-item"
                  href="javascript:void(0)"
                  @click="moveTo('/pagamentos/gerencianet/enviar')"
                >
                  Enviar Gerencianet
                </a>
              </li>
              <li>
                <a
                  class="dropdown-item"
                  href="javascript:void(0)"
                  @click="moveTo('/pagamentos/gerencianet/notificacoes')"
                >
                  Resumo notificações Gerencianet
                </a>
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown" v-if="state.menu.manifestacaoDestinatario">
            <a
              class="nav-link dropdown-toggle"
              href="javascript:void(0)"
              role="button"
              data-bs-toggle="dropdown"
            >
              Manifestação Destinatário
            </a>
            <ul class="dropdown-menu">
              <li>
                <a
                  class="dropdown-item"
                  href="javascript:void(0)"
                  @click="moveTo('/distribuicaoDFe/resumoNFes')"
                >
                  Manifestar / Resumo NFes 
                </a>
              </li>
              <li>
                <a
                  class="dropdown-item"
                  href="javascript:void(0)"
                  @click="moveTo('/distribuicaoDFe/nsus')"
                >
                  NSUs
                </a>
              </li>
              <li><hr class="dropdown-divider" /></li>
              <li>
                <a
                  class="dropdown-item"
                  href="javascript:void(0)"
                  @click="moveTo('/distribuicaoDFe/consultar')"
                >
                  Consultar Sefaz
                </a>
              </li>
            </ul>
          </li>
          <li class="nav-item" v-if="state.menu.dashboard">
            <a class="nav-link hand" aria-current="page" @click="moveTo('/dashboard')">
              Dashboard
            </a>
          </li>
        </ul>
        <ul class="navbar-nav ms-auto d-flex">
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="javascript:void(0)"
              role="button"
              data-bs-toggle="dropdown"
            >
              <i class="bi bi-info-circle me-1"></i>
              Sistema
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <a
                  class="dropdown-item disabled"
                  href="javascript:void(0)"
                  data-bs-toggle="collapse"
                >
                  <i class="bi bi-gear me-1"></i>
                  Configurações
                </a>
              </li>
              <li>
                <hr class="dropdown-divider" />
              </li>
              <li>
                <a
                  class="dropdown-item"
                  href="javascript:void(0)"
                  @click="moveTo('/informacoes')"
                  data-bs-toggle="collapse"
                >
                  <i class="bi bi-info-circle me-1"></i>
                  Informações do Sistema
                </a>
              </li>
            </ul>
          </li>          
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="javascript:void(0)"
              role="button"
              data-bs-toggle="dropdown"
            >
              <i class="pi pi-user me-1" />
              Usuário
            </a>
            <ul class="dropdown-menu dropdown-menu-end" v-if="user">
              <li class="dropdown-label" v-if="user">{{ user.name }}</li>
              <li class="dropdown-label" v-if="user.isAdmin ?? false">ADMINISTRADOR</li>
              <li class="dropdown-label">{{ fingerprint }}</li>
              <li><hr class="dropdown-divider" /></li>
              <li>
                <a
                  class="dropdown-item"
                  href="javascript:void(0)"
                  @click="Logout"
                  data-bs-toggle="collapse"
                  data-bs-target="#headernavbar"
                >
                  <i class="bi bi-box-arrow-right"></i>
                  Sair
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  
</template>

<script setup>
import InfoServico from "@/servicos/InfoServico.js";
import { ref, reactive, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const store = useStore();
const router = useRouter();
const servico = ref(null);

const state = reactive({
  empresa: null,
  informacoes: null,
  menu: null
});

const fingerprint = computed(() => store.getters.fingerprint);
const user = computed(() => { 
  return store.getters.user
})

onMounted(async () => {
  servico.value = new InfoServico();
  await servico.value.Online();
  state.informacoes = await servico.value.ObterSistema();
  state.menu = await servico.value.ObterMenu();
});

const moveTo = (url) => {
  var myCollapse = document.getElementById("headernavbar");
  if (myCollapse.classList.contains("show")) {
    myCollapse.classList.remove("show");
  }

  router.push(url);
};

const Logout = () => {
  store.dispatch("AUTH_LOGOUT");
  router.push("/login");
};

</script>


<style>
.pull-right {
  float: right !important;
}
.navbar .nav.pull-right {
  float: right !important;
  margin-right: 0 !important;
}

.dropdown-label {
  padding: 0.25rem 1rem;
}
</style>
