import axios from "axios";
import AuthServico from "@/servicos/AuthServico";
import store from "@/store";
import router from "@/router";
import DashboardServico from "@/servicos/DashboardServico";

export const baseApiUrl = process.env.NODE_ENV === 'development' ? 'http://192.168.1.100:7701' : process.env.VUE_APP_API_URL

const httpClient = axios.create({
  baseURL: baseApiUrl
})

httpClient.interceptors.request.use(config => {
  const token = store.getters.token
  config.headers.common.tenantid = store.getters.tenantid ?? '00.000.000/0000-00';
  if (token) {
    config.headers.common.Authorization = `Bearer ${token}`;
  }

  return config;
})

httpClient.interceptors.response.use((response) => response, (error) => {
  const canThrowError = error.request.status ===  0 || error.request.status === 500;

  if (canThrowError) {
    throw new Error(error.message);
  }

  if (error.request.status === 401) {
    store.commit('AUTH_LOGOUT')
    router.push({ name: 'Login' })
  }

  return error
})

export default {
  auth: AuthServico(httpClient),
  dashboard: DashboardServico(httpClient),
  API_URL: baseApiUrl
}