import Http from '@/http.js'

const state = () => ({
  vendas: [],
  pedidoPadrao: null
})

// mutations
const mutations = {
  DefinirVendas (state, vendas) {
    state.vendas = vendas
  },
  AtualizarVenda (state, venda) {
    let i = state.vendas.map(x => x.idPedido).indexOf(venda.idPedido)
    if (i >= 0) {
      state.vendas[i] = venda
    } else {
      state.vendas.push(venda)
    }
  },
  PedidoPadrao(state, value) {
    state.pedidoPadrao = value
  }  
}

// actions
const actions = {
  AtualizarVenda(context, idVenda) {
    Http.get(`/api/pedido/${idVenda}/tabela`)
    .then((response) => {
      context.commit('AtualizarVenda', response.data.data)
    })
  }
}

// getters
const getters = {
  vendas: state => state.vendas,
  pedidoPadrao: state => state.pedidoPadrao
}

export default {
  state,
  getters,
  actions,
  mutations
}