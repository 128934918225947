import axios from 'axios'
import store from '@/store/index.js'

export let baseApiUrl = process.env.NODE_ENV === 'development' ? 'http://192.168.1.100:7701' : process.env.VUE_APP_API_URL

const http = axios.create({
  baseURL: baseApiUrl
});

console.warn('Http deprecated')

http.interceptors.request.use(
  request => {
    const authToken = store.getters.token
    request.headers.common.tenantid = store.getters.tenantid ?? '00.000.000/0000-00';

    if (authToken) {
      request.headers.authorization = `Bearer ${authToken}`;
    }
    return request
  },
  error => Promise.reject(error)
)

http.interceptors.response.use(
  
  function (response) {
    store.commit('ONLINE', true)
    if (response.request.responseURL.includes('/Identity/Account/Login?ReturnUrl')) {
      window.location.href = response.request.responseURL
      return null
    }
    return response
  },
  function (error) {
    if (!error.response) {
      store.commit('ONLINE', false)
      console.error("Verificar sua conexão com a internet")

      return Promise.reject(error)
    }
    if (error.response.status === 401 || error.status === 401) {      
      store.dispatch('AUTH_LOGOUT')
      return Promise.reject(error)
    }
    return Promise.reject(error)
  })

export default http
