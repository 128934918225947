
const state = () => ({
  pagamentos: []
})

// mutations
const mutations = {
  DefinirPagamentos (state, pagamentos) {
    state.pagamentos = pagamentos
  },
  AtualizarPagamento (state, pagamento) {    
    let i = state.pagamentos.map(x => x.idPagamento).indexOf(pagamento.idPagamento)
    if (i >= 0) {
      state.pagamentos[i] = pagamento
    } else {
      state.pagamentos.push(pagamento)
    }
  },
  RemoverPagamento (state, idPagamento) {
    let i = state.pagamentos.map(x => x.idPagamento).indexOf(idPagamento)
    if (i < 0) { return }
    state.pagamentos.splice(i, 1)
  }
}

// actions
const actions = {
}

// getters
const getters = {
  pagamentos: state => state.pagamentos
}

export default {
  state,
  getters,
  actions,
  mutations
}