<template>
  <Ocupado />
  <ApHeader v-if="exibirHeader" />
  <div class="content">
    <router-view />    
  </div>
  <Toast position="bottom-right" />
  <ConfirmDialog></ConfirmDialog>
  <ApFooter v-if="exibirHeader" />
</template>

<script>
import { computed, onMounted } from "vue";

import ApHeader from "@/components/Header.vue";
import ApFooter from "@/components/Footer.vue";
import Ocupado from "@/components/Ocupado.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import InfoServico from "@/servicos/InfoServico";

export default {
  name: "App",
  components: {
    ApHeader,
    ApFooter,
    Ocupado,
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    onMounted(async () => {
      if (!store.getters.fingerprint) {
        await ObterFingerprint()
      }

      if (router.currentRoute.value.meta.publico === true) {
        return
      }      

      await ObterDefaultValues()      

      if (store.getters.municipios.length <= 0) {
        store.dispatch("CarregarMunicipios");
      }      
    });

    const exibirHeader = computed(() => {
      try {
        return store.getters.exibirHeader && store.getters.authenticated;  
      } catch (error) {
        return false
      }
    });

    async function ObterFingerprint () {
      const fpPromise = FingerprintJS.load()      
      const fp = await fpPromise
      const result = await fp.get()
      
      store.commit('DefinirFingerprint', result.visitorId)
    }

    async function ObterDefaultValues () {
      try {
        var servico = new InfoServico()
        var values = await servico.ObterInfoAvancado()
        store.commit('DefaultValues', values)
        store.commit('PedidoPadrao', values.pedidoPadrao)        
      } catch (error) {
        console.error(error)
      }
    }

    return {
      exibirHeader
    };
  },
};
</script>
