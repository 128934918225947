import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import HttpClient from '@/servicos/HttpClient'
import Swal from 'sweetalert2'

import PrimeVue from 'primevue/config'
import ConfirmDialog from 'primevue/confirmdialog';
import Column from 'primevue/column'
import ConfirmationService from 'primevue/confirmationservice';
import DataTable from 'primevue/datatable'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import ToastService from 'primevue/toastservice'
import Toast from 'primevue/toast'
import Tooltip from 'primevue/tooltip'

import 'bootstrap/dist/js/bootstrap.bundle'

import 'primevue/resources/primevue.min.css'
import 'primevue/resources/themes/bootstrap4-light-blue/theme.css'
import 'primeicons/primeicons.css'
import 'bootstrap-icons/font/bootstrap-icons.css'

import '@/styles/custom.scss'

import Subheader from "@/components/Subheader.vue"

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import { Money3Component } from "v-money3";

import { FormatarCpfCnpj, FormatCurrency, FormatQtq, FormatDate, FormatDateTime } from '@/tools/Format.js'

const app = createApp(App);

app.config.globalProperties.$swal = Swal
app.config.globalProperties.$formatarMoeda = FormatCurrency
app.config.globalProperties.$formatarQtd = FormatQtq
app.config.globalProperties.$formatarData = FormatDate
app.config.globalProperties.$formatarDataHora = FormatDateTime
app.config.globalProperties.$formatarCpfCnpj = FormatarCpfCnpj
app.config.globalProperties.$baseApiUrl = HttpClient.API_URL
app.directive('tooltip', Tooltip)

app.use(PrimeVue , {
  ripple: true,
  inputStyle: 'filled',
  zIndex: {
    modal: 1100,        //dialog, sidebar
    overlay: 1000,      //dropdown, overlaypanel
    menu: 1000,         //overlay menus
    tooltip: 1100       //tooltip
  }  
});

app.component('Datepicker', Datepicker);
app.component('DataTable', DataTable)
app.component('Column', Column)
app.component('TabView', TabView)
app.component('TabPanel', TabPanel)
app.component('Toast', Toast)
app.component('ConfirmDialog', ConfirmDialog)

app.component("money3", Money3Component);

app.component('Subheader', Subheader)

// TOASTSERVICE está depreciado
// usar 
//import { useToast } from "primevue/usetoast";
//const toast = useToast()

app.use(ConfirmationService)
app.use(ToastService)
app.use(store)
app.use(router)
app.mount('#app')
