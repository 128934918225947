<template>
  <div class="overlay" v-if='ocupado'>
    <div class="loading-circle"></div>
    <p class="loading-text">Aguarde...</p>  
  </div>
</template>

<script>
export default {
  computed: {
    ocupado () {
      return this.$store.getters.ocupado
    }    
  }
}
</script>

<style>
.loading-circle {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 2px solid transparent;
  border-left-color: #ff8817;
  animation: circleanimation .45s linear infinite
}

.loading-text {
  margin-top: 15px;
  color: #ff8817;
  font-size: 14px;
  text-align: center;
}

.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;  
  z-index: 1056;
  flex-direction: column;
  user-select: none;
  background-color: rgba(0,0,0,0.7);
}

@keyframes circleanimation {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}
</style>
